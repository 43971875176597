@media only screen and (max-width: 768px) {
    .label-responsive {
        text-align: left !important;
    }
}

@media only screen and (min-width: 768px) {
    .label-responsive {
        text-align: right !important;
    }
}

.save-button{
    padding-left: 20px !important;
    padding-right: 20px !important;
    height: 40px;
    font-size: initial;
    background-color: #1AB394 !important;
}

.period-button{
    padding-right: 10px !important;
    padding-left: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 0.7em;
    background-color: #1AB394 !important;
}

.btn-group-lg>.btn, .btn-lg {
    padding: .5rem 2rem !important;
    font-size: 1.125rem;
    line-height: 1.5;
    border-radius: .5rem;
}

.custom-root {
    width: '100%';
    overflow-x: 'auto';
}

.custom-table {
    min-width: 650;
}

.custom-cell {
    border: 1px solid #E0E0E0;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    font-size: 0.9em !important;
}

.custom-lp-cell {
    border: 1px solid #E0E0E0;
    padding: 7px !important;
}

.custom-header-cell {
    background-color: #2F4050 !important;
    color: #DDD !important;
    font-weight: bold;
    border: 1px solid #E0E0E0;
    padding: 10px !important;
}

.badge-pill {
    border-radius: .2rem !important;
}

.card {
    border-radius: 0.3rem !important;
}

.main-sidebar .nav .nav-item .nav-link.active i, .main-sidebar .nav .nav-item .nav-link:hover i, .main-sidebar .nav .nav-item.active i, .main-sidebar .nav .nav-item:hover i {
    color: white !important;
}

.main-sidebar .nav .nav-item .nav-link.active, .main-sidebar .nav .nav-item .nav-link:hover, .main-sidebar .nav .nav-item.active, .main-sidebar .nav .nav-item:hover {
    box-shadow: inset 0.1875rem 0 0 #19AA8D !important;
    color: white !important;
}

.custom-action-button {
    font-size: 0.8em;
    margin-left: 2px;
    margin-right: 2px;
}

.custom-action-white-button {
    font-size: 0.8em;
    margin-left: 2px;
    margin-right: 2px;
    color: white !important;
}

.deleted-cell {
    text-decoration: line-through !important;
}

.active-cell {
    font-weight: 400 !important;
}

.MuiTablePagination-root {
    border: none !important;
}

.total-cell {
    border: none;
    color: white !important;
    font-size: 1em !important;
    background-color: #1AB394;
}

.card-title-text {
    color: #ED5565;
}

.odd-cell {
    background-color: #EDEDED;
}

.copy-button{
    font-size: 0.8em;
    height: 33px;
    background-color: #1AB394 !important;
}

.custom-form-textarea {
    min-height: 60px;
}

.custom-form-big-textarea {
    min-height: 170px;
}

.custom-block-border {
    border-bottom: 1px #E1E5EB;
}

.form-control:focus {
    border-color: #1AB394 !important;
    box-shadow: 0px 1px 1px rgba(26, 179, 148, 0.075) inset, 0px 0px 8px rgba(26, 179, 148, 0.2)  !important;
}

input {
    font-size: 1em !important;
}

select {
    font-size: 1em !important;
}

textarea {
    font-size: 1em !important;
}

.custom-row-cursor {
    cursor: pointer !important;
}

.makeStyles-error-274 {
    background-color: #d32f2f !important;
}

.red-table-cell {
    background-color: rgb(238, 85, 101) !important;
    color: white !important;
}

.MuiLinearProgress-root {
    top: 4.0em;
    left: 0px;
    width: 100%;
    z-index: 100;
    position: fixed !important;
}

.clear-action-box {
    position: absolute;
    right: 10px;
    top: 10px;
}

.custom-table-header-cell {
    background-color: #2F4050;
    padding: 10px;
}

.custom-table-title-text {
    color: white !important;
}